import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home pages
const Home = lazy(() => import("./pages/home/Home"));

// shop pages
const Shop = lazy(() => import("./pages/shop/Shop"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const Order = lazy(() => import("./pages/other/Order"));

// other pages
// const About = lazy(() => import("./pages/other/About"));
// const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = (props) => {
  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="TringleSpices-preloader-wrapper">
                  <div className="TringleSpices-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route path={"/"} component={Home} exact />

                {/* Shop pages */}
                <Route path={"/shop"} component={Shop} />
                {/* Shop product pages */}

                <Route
                  path={"/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route
                  path={"/order/:id"}
                  render={(routeProps) => (
                    <Order {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route path={"/my-account"} component={MyAccount} />
                <Route path={"/login-register"} component={LoginRegister} />

                <Route path={"/cart"} component={Cart} />
                <Route path={"/checkout"} component={Checkout} />

                <Route path={"/not-found"} component={NotFound} />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(App);
