import "./bootstrap.min.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import "./assets/scss/style.scss";
import "./index.css";
import { composeWithDevTools } from "redux-devtools-extension";

const store = createStore(
  rootReducer,
  load({ states: ["cartData", "userLogin", "userRegister"] }),
  composeWithDevTools(
    applyMiddleware(
      thunk,
      save({ states: ["cartData", "userLogin", "userRegister"] })
    )
  )
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
